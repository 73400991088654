import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import EnvContext from './EnvContext';
import { toast } from 'react-toastify';
import axios from 'axios';
import CourseContext from './CourseListContext';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';

const ContentContext = createContext();

export const ContentContextProvider = ({ children }) => {
  const [contents, setContents] = useState([]);
  const [content, setContent] = useState({});
  const [decryptedText, setDecryptedText] = useState('');
  const [decryptedVideo, setDecryptedVideo] = useState('');
  const [contentId, setContentId] = useState(null);
  // const [videoDuration, setVideoDuration] = useState('');
  const [lastWatchedPoint, setLastWatchedPoint] = useState(0);
  const { courseId, courseData } = useContext(CourseContext);
  const { backendUrl } = useContext(EnvContext);
  const [apiCalled, setApiCalled] = useState(true);
  const [scorecard, setScoreCard] = useState({});
  const [assign, setAssign] = useState([]);
  const CRYPTO_KEY = process.env.REACT_APP_CRYPTO_KEY;
  const [index, setIndex] = useState(null);
  const [projectChartData, setProjectChatData] = useState([]);
  const [watchedContentChatData, setWatchedContentChatData] = useState([]);
  const [assignmentChatData, setAssignmentCharData] = useState([]);
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [rank, setRank] = useState(0);
  const [hasMarkAsRead, sethasMarkAsRead] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const COLORS = ['#2196F3', '#9E9E9E', '#4CAF50', '#F44336', '#FF8012'];
  const CONTENT_COLORS = ['#2196F3', '#9E9E9E'];
  const ASSIGNMENT_COLORS = ['#4CAF50', '#F44336', '#9E9E9E'];

  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  // Function for decrypted content
  // const decryptContent = encryptedText => {
  //   try {
  //     const bytes = CryptoJS.AES.decrypt(encryptedText, CRYPTO_KEY);
  //     return bytes.toString(CryptoJS.enc.Utf8);
  //   } catch (error) {
  //     console.error('Decryption Error:', error);
  //     return null;
  //   }
  // };

  // Function to encrypted data
  // const encryptedContent = (plainData, key) => {
  //   return CryptoJS.AES.encrypt(plainData, key).toString();
  // };

  useEffect(() => {
    if (scorecard && Array.isArray(scorecard.assignment)) {
      const correctAnswers = scorecard.assignment.filter(
        item => item.isCorrectAnswer === true
      );
      const uniqueWrongAnswers = [
        ...new Set(
          scorecard.assignment
            .filter(item => !item.isCorrectAnswer)
            .map(item => item?.question?._id)
        ),
      ];

      // Now you can use correctAnswers array for further processin
      setAssign(correctAnswers);
      setAssignmentCharData([
        {
          name: 'Corerct',
          value:
            correctAnswers &&
            Array.isArray(correctAnswers) &&
            correctAnswers.length,
        },
        {
          name: 'Wrong',
          value:
            uniqueWrongAnswers &&
            Array.isArray(uniqueWrongAnswers) &&
            uniqueWrongAnswers.length,
        },
        {
          name: 'Not Attempted',
          value:
            correctAnswers &&
            Array.isArray(correctAnswers) &&
            uniqueWrongAnswers &&
            Array.isArray(uniqueWrongAnswers) &&
            correctAnswers.length + uniqueWrongAnswers.length <=
              courseData?.totalQnaCount
              ? courseData?.totalQnaCount -
                (correctAnswers.length + uniqueWrongAnswers.length)
              : 0,
        },
      ]);
    }
  }, [scorecard]);

  const [latestSlNo, setUpdatedSlNo] = useState(null);

  // Function to update the array
  const updateContents = () => {
    const len = contents.length;
    if (len > 0) {
      const lastLen = len - 1;
      const lastSl_no = contents[lastLen].sl_no;
      const newSl_no = Math.floor(lastSl_no);
      setUpdatedSlNo(newSl_no + 1);
    }
  };
  useEffect(() => {
    if (contents) updateContents();
  }, [contents]);

  const fetchAllContent = useCallback(async () => {
    setLoading(true);
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };

    try {
      const { data } = await axios.get(
        `${backendUrl}/course/content/all?courseId=${courseId}`,
        { headers }
      );
      setContents(data.data);
    } catch (error) {
      console.error('Error loading contents:', error);
      // if (error.status === 403) {
      //   navigate('/vendor-managment/video');
      // }
      toast.error(error?.response?.data?.message || 'Failed to load contents');
    } finally {
      setLoading(false);
    }
  }, [courseId]);

  const fetchContent = useCallback(async () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
    try {
      const { data } = await axios.get(
        `${backendUrl}/course/content?contentId=${contentId}`,
        { headers }
      );
      setContent(data.data);
    } catch (error) {
      console.error('Error occured in fetchcontent function:', error);
    }
  }, [contentId]);

  const handleToggleLike = async () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
    if (contentId) {
      try {
        await axios
          .put(
            `${backendUrl}/course/content/toogle_like?contentId=${contentId}`,
            null,
            {
              headers: headers,
            }
          )
          .then(res => {
            fetchContent();
            // setScoreCard(res.data.data)
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  // const fetchEncryptedContent = async (id, lastWatchedPoint) => {
  //   const headers = {
  //     Authorization: `Bearer ${localStorage.getItem('token')}`,
  //   };
  //   const cacheName = 'video-cache';

  //   try {
  //     // Check Cache first
  //     const startDate = Date.now();
  //     const cache = await caches.open(cacheName);
  //     const cachedResponse = await cache.match(id);

  //     if (cachedResponse) {
  //       const cachedData = await cachedResponse.json();
  //       console.log('cachedData:', cachedData);
  //       if (cachedData.video.encryptedVideo) {
  //         const decryptedVideo = decryptContent(
  //           cachedData.video.encryptedVideo,
  //           CRYPTO_KEY
  //         );
  //         setDecryptedVideo(decryptedVideo);
  //       }
  //       // setLastWatchedPoint(Number(cachedData.lastWatchedPoint) || 0);
  //       console.log('Content loaded from cache');
  //       const endDate = Date.now();
  //       const responseTimeCache = endDate - startDate;
  //       console.log('responseTimeCache:', responseTimeCache);
  //       return;
  //     }

  //     // If not in cache, fetch from server
  //     const startTime = Date.now(); // Timestamp before API call
  //     const res = await axios.get(
  //       `${backendUrl}/course/content/vod?contentId=${id}`,
  //       { headers }
  //     );
  //     const data = res.data.data;
  //     console.log('data from backend:', data);
  //     const endTime = Date.now(); // Timestamp after the API response

  //     const apiResponseTime = endTime - startTime;
  //     console.log('response time:', apiResponseTime);

  //     if (data.video) {
  //       const decryptedVideo = decryptContent(data?.video, CRYPTO_KEY);
  //       if (decryptedVideo) {
  //         setDecryptedVideo(decryptedVideo);

  //         const encryptedVideo = encryptedContent(decryptedVideo, CRYPTO_KEY);
  //         console.log('encryptedVideo', encryptedVideo);
  //         const jsonData = JSON.stringify(encryptedVideo);
  //         console.log('JSON data in ContentContext', jsonData);

  //         // Save decrypted video to cache
  //         await cache.put(
  //           id,
  //           new Response(
  //             JSON.stringify({
  //               video: { encryptedVideo },
  //               lastWatchedPoint: data?.lastWatchedPoint || 0,
  //             })
  //           )
  //         );
  //       }
  //     } else if (data.docContent) {
  //       const decryptedText = decryptContent(data?.docContent, CRYPTO_KEY);
  //       if (decryptedText) setDecryptedText(decryptedText);
  //     }
  //     const lastWatchedPointNumber = +res.data.data.lastWatchedPoint;
  //     setLastWatchedPoint(Number(lastWatchedPointNumber) || 0);
  //   } catch (error) {
  //     console.log('Error fetching encrypted content:', error);
  //   }
  // };

  const fetchEncryptedContent = useCallback(async id => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await axios
        .get(`${backendUrl}/course/content/vod?contentId=${id}`, {
          headers: headers,
        })
        .then(res => {
          if (res.data.data.video) {
            const bytes = CryptoJS.AES.decrypt(res.data.data.video, CRYPTO_KEY);
            const originalText = bytes.toString(CryptoJS.enc.Utf8);
            // console.log("originalText",originalText,"path",res.data.data.video,"bytes",bytes)
            setDecryptedVideo(originalText);
          } else {
            const bytes = CryptoJS.AES.decrypt(
              res.data.data.docContent,
              CRYPTO_KEY
            );
            const originalText = bytes.toString(CryptoJS.enc.Utf8);

            setDecryptedText(originalText);
          }

          if (!res.data.data.lastWatchedPoint) {
            setLastWatchedPoint(0);
          } else {
            const lastWatchedPointNumber = +res.data.data.lastWatchedPoint;
            setLastWatchedPoint(lastWatchedPointNumber);
          }
        })
        .catch(err => {
          console.log('err', err);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  // Reading status api
  const addReadingStatus = async postData => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
    try {
      await axios.post(`${backendUrl}/user/rs/curr_status`, postData, {
        headers,
      });
    } catch (error) {
      console.log('error');
    }
  };

  // function to call userScroreBoard api
  const fetchUserScoreboard = async (userId, courseId) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
    try {
      await axios
        .get(
          `${backendUrl}/user/rs/scorecard?userId=${userId}&courseId=${courseId}`,
          { headers }
        )
        .then(res => {
          setScoreCard(res.data.data);
        });
    } catch (err) {
      console.log(err);
    }
  };

  // function for fetchLeaderBoard api
  const fetchLeaderBoard = async () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
    try {
      const response = await axios.get(
        `${backendUrl}/user/rs/leaderboard?courseId=${courseId}`,
        { headers }
      );

      setLeaderBoard(response.data.leaderboard);
      setRank(response.data.userRank);
    } catch (error) {
      console.log('Error', error);
      // toast.error(
      //   error.response ? error.response.data.message : 'Something Went Wrong',
      //   {
      //     position: 'top-right',
      //     style: {
      //       marginTop: '62px',
      //     },
      //   }
      // );
    }
  };

  useEffect(() => {
    sethasMarkAsRead(false);
  }, [contentId]);

  const markAsRead = async (userId, courseId, contentId) => {
    if (hasMarkAsRead) return;

    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await axios.put(
        `${backendUrl}/user/rs/mark_as_read?userId=${userId}&courseId=${courseId}&contentId=${contentId}`,
        null,
        { headers }
      );

      sethasMarkAsRead(true);
      fetchUserScoreboard(userId, courseId);
      fetchLeaderBoard();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserCurrentReadingStatus = async userId => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
    try {
      await axios
        .get(`${backendUrl}/user/rs/curr_status?userId=${userId}`, {
          headers: headers,
        })
        .then(res => {
          console.log('CurrentReadingStatus fetched', res.data.data);
          // setScoreCard(res.data.data)
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ContentContext.Provider
      value={{
        setDecryptedText,
        decryptedText,
        fetchEncryptedContent,
        fetchAllContent,
        latestSlNo,
        contents,
        setContentId,
        // setToken,
        contentId,
        fetchContent,
        content,
        addReadingStatus,
        lastWatchedPoint,
        markAsRead,
        // setVideoDuration,
        // videoDuration,
        setApiCalled,
        apiCalled,
        fetchUserScoreboard,
        scorecard,
        decryptedVideo,
        setDecryptedVideo,
        fetchUserCurrentReadingStatus,
        assign,
        setIndex,
        index,
        projectChartData,
        setProjectChatData,
        COLORS,
        watchedContentChatData,
        setWatchedContentChatData,
        CONTENT_COLORS,
        assignmentChatData,
        ASSIGNMENT_COLORS,
        setLeaderBoard,
        leaderBoard,
        fetchLeaderBoard,
        rank,
        handleToggleLike,
        sethasMarkAsRead,
        loading,
        headers,
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};

export default ContentContext;

export const useContent = () => useContext(ContentContext);
