import { createContext, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import LogoutModal from '../components/user/modals/LogoutModal';

// Create a context for authentication
const AuthContext = createContext({
  userId: null,
  role: null,
  userData: null,
  setUser: () => {},
  handleSignOut: () => {},
  errorHandleLogout: () => {},
  handleUserData: () => {},
  vendorCourseData: null,
});

// Provider component for managing authentication state
export const AuthContextProvider = ({ children }) => {
  // State variables
  const [isModalOpen, setModalOpen] = useState(false); // State for controlling logout confirmation modal
  const [userData, setUserData] = useState({}); // Storing for user data
  const [blockedUsers, setBlockedUsers] = useState([]); // State for storing blocked users
  const [loading, setLoading] = useState(true); // State for loading status
  const [users, setUsers] = useState([]); // State for storing all users
  const [products, setProducts] = useState([]); // State for storing products
  const [isChecked, setIsChecked] = useState(true); // State for managing check box(remeber me)
  const [vendorCourseData, setVendorCourseData] = useState(null); // State for managing

  // Backend Url
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const toast = useToast();
  const savedLocation = localStorage.getItem('savedLocation');

  // Function to clear specified keys from localStorage
  const clearLocalStorage = () => {
    const keysToRemove = [
      'token',
      'userId',
      'courseId',
      'role',
      'adminDp',
      'userDp',
      'adminName',
      'userName',
      'lastRoute',
      'firstFive',
      'tabIndex',
    ];
    keysToRemove.forEach(key => localStorage.removeItem(key));
  };

  // Function to Fetched user data
  const handleUserData = async userId => {
    const token = localStorage.getItem('token');
    if (!token) return; // Exit early if no token is found

    const headers = { Authorization: `Bearer ${token}` };
    const apiUrl = `${BACKEND_URL}/user${userId ? `?userId=${userId}` : ''}`;

    try {
      const { data } = await axios.get(apiUrl, { headers });
      const user = data.user; // Extract user data directly

      console.log('user:', user);

      // Combine assigned and added courses for vendors
      const assignedCourses = user?.vendorCourses?.assignedCourses || [];
      const addedCourses = user?.vendorCourses?.addedCourses || [];
      const allCourses = [...assignedCourses, ...addedCourses]; // Merge the two course arrays

      setUserData(user);
      setVendorCourseData(allCourses);

      // Store user details in localStorage
      const userRole = user.role === 'admin' ? 'admin' : 'user'; // Determine user role
      localStorage.setItem(`${userRole}Dp`, user?.dp); // Store profile picture
      localStorage.setItem(`${userRole}Name`, user?.name); // Store user name
    } catch (err) {
      console.error('User data fetch error:', err); // Use console.error for error logging
    }
  };

  // Function to handle user login
  const setUser = async value => {
    try {
      const res = await axios.post(`${BACKEND_URL}/user/signin`, value);
      console.log('response:', res);
      const { token, userId, role } = res.data.data;

      // Store token and user info in localstorage
      localStorage.setItem('token', token);
      localStorage.setItem('userId', userId);
      localStorage.setItem('role', role);

      handleUserData(userId); // Fetched User data

      // Store email and password if 'Remember me" is checked
      if (isChecked) {
        localStorage.setItem('rememberedEmail', value.email);
        localStorage.setItem('rememberedPassword', value.password);
      }

      toast({
        title: 'Sign in successful!',
        description: `Welcome back! ${
          role === 'admin'
            ? localStorage.getItem('adminName')
            : localStorage.getItem('userName')
        }`,
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });

      // Navigate to the appropriate dashboard based user role
      navigate(
        role === 'user'
          ? savedLocation || '/dashboard'
          : role === 'admin'
          ? '/admin/site_manager'
          : role === 'vendor'
          ? '/admin/vendor-user'
          : '/'
      );
    } catch (err) {
      toast({
        title: 'Sign in failed.',
        description: 'Please check your credentials and try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  // Function to open the logout confirmation modal
  const handleSignOut = () => {
    setModalOpen(true); // Open the custom modal
  };

  // Function to confirm logout
  const confirmSignOut = () => {
    clearLocalStorage(); // Clear localstorage
    toast({
      title: 'Logged Out Successfully.',
      status: 'success',
      position: 'top-right',
      duration: 1000,
      isClosable: true,
    });

    // Close the modal after logout is confirmed
    setModalOpen(false);

    // Navigate to homepage after logout
    navigate('/');
  };

  // Function to handle logout errors (if neccessary)
  const errorHandleLogout = () => {
    toast({
      title: 'LogOut Successfully.',
      status: 'success',
      position: 'top-right',
      duration: 1000,
    });
    clearLocalStorage(); // Clear local storage
    navigate('/'); // Navigate to homepage
  };

  // Function to fetch specific users based on course ID
  const getSpecificUsers = async courseId => {
    if (localStorage.getItem('role') !== 'admin') return; // Check if user is admin

    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };
    let apiUrl = `${BACKEND_URL}/user/course/specific_type?courseId=${
      courseId || ''
    }`;

    try {
      const res = await axios.get(apiUrl, { headers });
      setUsers(res.data.users); // Update state with users
      setLoading(true); // Set loading state
    } catch (err) {
      console.log('Error fetching specific users:', err);
    }
  };

  // Function to block a user
  const blockUser = async userId => {
    try {
      await axios.delete(`${BACKEND_URL}/user/block?userId=${userId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      toast({
        title: 'User blocked successfully',
        status: 'success',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
      getSpecificUsers(); // Referesh the user list
      getBlockUser(); // Refresh blocked users
    } catch (err) {
      toast({
        title: 'Failed to check user.',
        status: 'error',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Function to fetch blocked users
  const getBlockUser = async () => {
    if (localStorage.getItem('role') !== 'admin') return; // Check if user is admin

    try {
      const res = await axios.get(`${BACKEND_URL}/user/blocked_list`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setBlockedUsers(res.data.users); // Update state with blocked users
    } catch (err) {
      console.log('Error fetching blocked users:', err);
    }
  };

  // Function to unblock a user
  const unBlockUser = async userId => {
    try {
      await axios.put(`${BACKEND_URL}/user/retrive?userId=${userId}`, null, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      getBlockUser(); // Refresh blocked users
      getSpecificUsers(); // Refresh specific users
    } catch (err) {
      console.log('Error unblocking user:', err);
    }
  };

  // Function to delete a user
  const handleDelete = async userId => {
    try {
      await axios.delete(`${BACKEND_URL}/user/temp?userId=${userId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      toast({
        title: 'User deleted successfully',
        status: 'success',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
      getSpecificUsers(); // Refersh user list
      getBlockUser();
    } catch (err) {
      toast({
        title: 'Failed to delete user!',
        description: `Operation could not be completed. Admin: ${localStorage.getItem(
          'adminName'
        )}`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  // Function to clean a user's account
  const userClean = async userId => {
    try {
      await axios.delete(`${BACKEND_URL}/user/clean_account?userId=${userId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      toast.success('User account cleaned successfully', {
        position: 'top-right',
      });
    } catch (err) {
      toast.error('Failed to clean user account', { position: 'top-right' });
    }
  };

  // Function fetch all products(Courses)
  const getAllProducts = useCallback(async () => {
    try {
      const res = await axios.get(`${BACKEND_URL}/course/all`);
      setProducts(res.data.courses); // Update state with products
    } catch (err) {
      console.log('Error fetching products:', err);
      toast({
        title: 'Error fetching products',
        description: err.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }, []);

  useEffect(() => {
    const storedValues = JSON.parse(sessionStorage.getItem('userData'));
    if (storedValues) setUserData(storedValues);
  }, []);

  useEffect(() => {
    sessionStorage.setItem('userData', JSON.stringify(userData));
  }, [userData]);

  // Return context provider with values and children
  return (
    <AuthContext.Provider
      value={{
        setUser,
        handleSignOut,
        errorHandleLogout,
        userData,
        handleUserData,
        blockUser,
        getBlockUser,
        blockedUsers,
        unBlockUser,
        handleDelete,
        userClean,
        getSpecificUsers,
        loading,
        users,
        isChecked,
        setIsChecked,
        products,
        setProducts,
        getAllProducts,
        vendorCourseData,
      }}
    >
      {/*Render children wrapped in the context provider */}
      {children}
      <LogoutModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={confirmSignOut}
      />
    </AuthContext.Provider>
  );
};

export default AuthContext;
