// starting date : 26 Feb 2024

import React, { useEffect } from 'react';
import { AllRoutes } from './routes/AllRoutes';
import './App.css';

function App() {
  useEffect(() => {
    localStorage.removeItem('chakra-ui-color-mode');
  }, []);

  return (
    <>
      <AllRoutes />
    </>
  );
}
// new updated file new commit
export default App;
