import React, { createContext, useContext, useEffect, useState } from 'react';
import EnvContext from '../../EnvContext';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { toast } from 'react-toastify';

export const GoogleAuthContext = createContext();

const GoogleAuthContextProvider = ({ children }) => {
  const { backendUrl } = useContext(EnvContext);
  const [user, setUser] = useState(null); // To store the authenticated user
  const navigate = useNavigate();

  // Trigger Google login
  const googleLogin = async () => {
    // Redirect the user to the backend, which will redirect them to Google OAuth
    window.location.href = `${backendUrl}/auth/google`;
  };

  // Handle Google 0Auth2.0 callback
  useEffect(() => {
    const handleGoogleCallback = () => {
      //parse the query parameters from the url

      const { google_login_data } = queryString.parse(window.location.search);

      if (google_login_data) {
        try {
          // Decode the URL-encoded JSON data
          const decodedData = decodeURIComponent(google_login_data);
          const loginData = JSON.parse(decodedData);
          console.log('loginData:', loginData);

          // Check if the login was successful
          if (loginData.success) {
            // Store the JWT in local storage
            localStorage.setItem('token', loginData.data.token);
            localStorage.setItem('userId', loginData.data.userId);
            localStorage.setItem('role', loginData.data.role);

            // Store user data in state
            setUser(loginData.data);

            toast.success(loginData.message);

            if (loginData.data.role === 'vendor') {
              navigate('/admin/vendor-user');
            } else if (loginData.data.role === 'admin') {
              navigate('/admin/home');
            } else {
              navigate('/dashboard');
            }

            // Redirect the user to the dashboard or another page
          } else {
            // Handle login failure
            console.log('Google login failed:', loginData.message);
            navigate('/');
          }
        } catch (error) {
          console.error('Error parsing Google login data:', error);
          navigate('/');
        }
      }
    };

    handleGoogleCallback();
  }, [navigate]);

  // Logout function
  const logout = () => {
    // Clear the token from local storage
    localStorage.removeItem('token');

    // Clear the user state
    setUser(null);

    // Redirect to the login page
    navigate('/');
  };

  return (
    <GoogleAuthContext.Provider value={{ googleLogin, user, logout }}>
      {children}
    </GoogleAuthContext.Provider>
  );
};

export default GoogleAuthContextProvider;
