// src/context/AllContextProviders.js

import React from 'react';
import { AuthContextProvider } from './AuthContext';
import { EnvContextProvider } from './EnvContext';
import { SyllabusContextProvider } from './SyllabusContext';
import { MentorContextProvider } from './MentorContext';
import { CourseContextProvider } from './CourseListContext';
import { ContentContextProvider } from './ContentContext';
import { AssignmentContextProvider } from './AssignmentContext';
import { ProjectContextProvider } from './ProjectContext';
import { CartContextProvider } from './CartContext';
import { WishListContextProvider } from './WishListContext';
import { CouponContextProvider } from './CouponContext';
import { SiteManagerContextProvider } from './SiteManagerContext';
import { PaymentContextProvider } from './PaymentContext';
import VendorContextProvider from './VendorContext/VendorContext';
import VideoContextProvider from './VendorContext/VideoContext';
import VendorContextExtraProvider from './VendorContext/VendorContextExtra';
import GoogleAuthContextProvider from './VendorContext/GoogleAuth/GoogleAuthContext';

const AllContextProviders = ({ children }) => {
  return (
    <EnvContextProvider>
      <GoogleAuthContextProvider>
        <AuthContextProvider>
          <SyllabusContextProvider>
            <MentorContextProvider>
              <CourseContextProvider>
                <ContentContextProvider>
                  <AssignmentContextProvider>
                    <ProjectContextProvider>
                      <CartContextProvider>
                        <WishListContextProvider>
                          <CouponContextProvider>
                            <SiteManagerContextProvider>
                              <PaymentContextProvider>
                                <VendorContextProvider>
                                  <VendorContextExtraProvider>
                                    <VideoContextProvider>
                                      {children}
                                    </VideoContextProvider>
                                  </VendorContextExtraProvider>
                                </VendorContextProvider>
                              </PaymentContextProvider>
                            </SiteManagerContextProvider>
                          </CouponContextProvider>
                        </WishListContextProvider>
                      </CartContextProvider>
                    </ProjectContextProvider>
                  </AssignmentContextProvider>
                </ContentContextProvider>
              </CourseContextProvider>
            </MentorContextProvider>
          </SyllabusContextProvider>
        </AuthContextProvider>
      </GoogleAuthContextProvider>
    </EnvContextProvider>
  );
};

export default AllContextProviders;
